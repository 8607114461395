<script setup>
import { SText, STitle } from '@n966/soilbox_ui'

const infoConfig = [
  { id: 'start', key: 'Старт', value: '8 октября 2024 года' },
  { id: 'duration', key: 'Длительность', value: '10 недель' },
  { id: 'format', key: 'Формат', value: 'Онлайн' }
]
</script>

<template>
  <div class="python-course">
    <img
      class="python-course__image-wrapper"
      src="@/assets/images/updates/python-course.png"
    />

    <div class="python-course__text-wrapper">
      <s-title type="title">
        Лаборатория GeoPython: Секреты Планеты [
        <a
          href="https://space-cognition.com/"
          target="_blank"
          >ссылка</a
        >
        ]
      </s-title>
      <s-text>
        Приглашаем Вас присоединиться к нашему традиционному курсу по анализу
        данных дистанционного зондирования Земли (ДЗЗ). Вы освоите потенциал
        Python и возможности API Google Earth Engine с доступом более чем к 70
        Пбайтам открытых данных о нашей Планете.
      </s-text>
      <s-text>
        Курс включает живые сессии с экспертами, практические задания и доступ к
        современным инструментам на языке Python.
      </s-text>

      <ul class="python-course__info">
        <li
          v-for="item in infoConfig"
          :key="item.id"
          class="python-course__info-item"
        >
          <s-text semibold>{{ item.key }}:</s-text>
          <s-text>{{ item.value }}</s-text>
        </li>
      </ul>

      <s-text>
        Подробнее:
        <a
          href="https://space-cognition.com/"
          target="_blank"
        >
          https://space-cognition.com/
        </a>
      </s-text>

      <s-text>
        <b>Скидка 5%</b> с промокодом <b><i>soilbox</i></b> для пользователей и
        подписчиков канала
        <a
          href="https://t.me/soilbox"
          target="_blank"
        >
          Soilbox </a
        >.
      </s-text>

      <s-text>
        P.S. В сентябре мы проведем вебинар, где расскажем о структуре курса,
        вариантах обратной связи и обсудим некоторые кейсы из предстоящего
        курса.
        <br />
        Регистрация на вебинар:
        <a
          href="https://space-cognition.com/#vebinar"
          target="_blank"
          >https://space-cognition.com/#vebinar</a
        >
      </s-text>
    </div>
  </div>
</template>

<style lang="scss">
.python-course {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    color: var(--primary);
  }

  &__image-wrapper {
    border: 1px solid var(--main-bg);
    border-radius: 8px;
    width: 100%;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__info {
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
      display: flex;
      gap: 0.5rem;
    }
  }
}
</style>
