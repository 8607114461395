import './styles/style.scss'
import { STooltip, SLoading, SNotify, SConfirm } from '@n966/soilbox_ui'
import { createApp } from 'vue'
import App from './App.vue'
import startMonitoring from '@/libs/sentry'
import vClickOutside from 'click-outside-vue3'
import { createPinia } from 'pinia'
import { axiosInstance } from '@/api/axios'
import posthogPlugin from '@/libs/posthog.js'
import bitrixPlugin from '@/libs/bitrix.js'
import { router } from './router'

const app = createApp(App)

const pinia = createPinia()

app.config.globalProperties.$axios = axiosInstance

app.directive('tooltip', STooltip)
app.directive('loading', SLoading)

app.use(SNotify)
app.use(SConfirm)
app.use(posthogPlugin)
app.use(bitrixPlugin)

startMonitoring(app)
app.use(pinia).use(router).use(vClickOutside).mount('#app')
