export const updatesConfig = [
  {
    title: 'Отличные новости!',
    date: '04 марта 2025',
    image: 'skolkovo_resident.jpg',
    descriptions: [
      {
        text: 'Друзья, рады сообщить, что сегодня Soilbox официально стал резидентом «Сколково» в инновационном кластере «Энерготех»!'
      },
      {
        text: 'Это было непростое приключение, которое нам предстояло пройти в сжатые сроки, но мы справились!'
      },
      {
        text: 'Двигаемся вперёд, развиваем технологии и делаем инженерные изыскания ещё эффективнее и привлекательнее! Большая благодарность всем, кто поддерживает нас на этом пути!'
      }
    ]
  },
  {
    title: 'Фильтр зон МСК на карте',
    date: '03 февраля 2025',
    image: 'wgsmsk_map_filters.png',
    list: [
      {
        text: 'Появилась возможность фильтрации зон по широте на карте.'
      }
    ]
  },
  {
    date: '21 августа 2024',
    component: 'python-course'
  },
  {
    title:
      'Рады представить новый функционал, разработанный по вашим просьбам. На сервис добавлено:',
    date: '15 апреля 2024',
    image: 'updates-image.png',
    list: [
      {
        text: 'Обратный <span>пересчет из MSK в WGS</span>. Зоны необходимо выбирать вручную. Используйте <span>инструмент протягивания ячейки</span> (как в Excel) с выбранной зоной для быстрого заполнения данных в столбце “Зона”.'
      },
      {
        text: '<span>Экспорт данных в DXF</span>, в режиме блоков или изолированных слоев для Autocad и Nanocad. Система координат – МСК.'
      },
      {
        text: '<span>Экспорт данных в KML/KMZ</span> для интеграции с ГИС. Система координат – WGS-84.'
      },
      {
        text: '<span>Экспорт данных в GPX</span> для использования в приемниках Garmin. Система координат – WGS-84.'
      }
    ]
  }
]
