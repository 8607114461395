<script setup>
import { onMounted, ref, computed } from 'vue'
import { useMainStore } from '@/store'
import TheHeader from '@/components/interface/the-header.vue'
import OverlayAlert from '@/components/common/overlay-alert.vue'
import { SCookie } from '@n966/soilbox_ui'

const windowWidth = ref(null)

onMounted(() => {
  const setVHValue = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    windowWidth.value = window.innerWidth
  }

  setVHValue()

  document.body.classList.add('dark-theme')

  window.addEventListener('resize', setVHValue)
  window.addEventListener('orientationchange', setVHValue)

  mainStore.toggleLoading(false)
})

const mainStore = useMainStore()

const isDesktop = computed(() => {
  return windowWidth.value >= 1200
})
</script>

<template>
  <div
    class="app"
    v-loading="mainStore.isLoading"
  >
    <TheHeader v-if="isDesktop" />
    <div v-if="!isDesktop">
      <overlay-alert />
    </div>
    <router-view v-else />
    <s-cookie />
  </div>
</template>

<style lang="scss">
.app {
  height: 100%;
}
</style>
