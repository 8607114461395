<script setup>
import { ref, watch, onMounted, computed, inject } from 'vue'
import useAxios from '@/composables/useAxios'
import { useTableStore } from '@/store/table'
import { useMainStore } from '@/store'
import { saveDataToLS, getDataFromLS } from '@/utils'
import {
  SButton,
  SIcon,
  SSelect,
  SDropdown,
  SDropdownItem
} from '@n966/soilbox_ui'
import { toDay } from '../helpers'
import { getZone } from '../helpers'
import cloneDeep from 'lodash.clonedeep'
import TableImportModal from '@/components/table/components/table-import/table-import-modal.vue'

const emits = defineEmits(['toolbar-action', 'select-handle', 'clear-table'])

const axios = useAxios()

const store = useTableStore()
const mainStore = useMainStore()
const $confirm = inject('$confirm')

const actionsItems = [
  {
    id: 1,
    iconName: 'fa-rotate-left',
    tooltipText: 'Отменить'
  },
  {
    id: 2,
    iconName: 'fa-rotate-right',
    tooltipText: 'Повторить'
  },
  {
    id: 3,
    iconName: 'fa-copy',
    tooltipText: 'Копировать'
  },
  {
    id: 4,
    iconName: 'fa-paste',
    tooltipText: 'Вставить'
  }
]
const sheetsActionsHandler = (id) => {
  emits('toolbar-action', id)
}

const clearTable = () => {
  let message =
    'Вы уверены, что хотите очистить таблицу? Все данные будут удалены без возможности восстановления'
  let title = 'Подтверждение очистки таблицы'

  $confirm(message, title, {
    confirmButtonText: 'Очистить',
    cancelButtonText: 'Отменить',
    confirmButtonClass: 'error'
  })
    .then(() => emits('clear-table'))
    .catch(() => {})
}

const excelExport = () => {
  emits('toolbar-action', 5)
}

const coordinateConversions = [
  { title: 'WGS -> MSK', id: 'WGS-MSK' },
  { title: 'MSK -> WGS', id: 'MSK-WGS' }
]

const stopWatch = watch(
  () => mainStore.isLoading,
  (newVal) => {
    if (!newVal) {
      selectHandle(defaultSelectedValue.value.id)

      stopWatch()
    }
  }
)

let defaultSelectedValue = ref(null)

const selectHandle = (id) => {
  const findedCoord = coordinateConversions.find((coord) => coord.id === id)
  if (findedCoord) {
    store.SET_STATE_FIELD('selectValue', findedCoord)
    defaultSelectedValue.value = findedCoord
    saveDataToLS('selectValue', findedCoord)
  }
  emits('select-handle', id)
}

const screenWidth = ref(window.innerWidth)

onMounted(() => {
  const defaultValue = getDataFromLS('selectValue')

  if (defaultValue) {
    defaultSelectedValue.value = defaultValue
  } else {
    defaultSelectedValue.value = coordinateConversions[0]
  }

  store.SET_STATE_FIELD('selectValue', defaultSelectedValue.value)

  window.addEventListener('resize', () => {
    screenWidth.value = window.innerWidth
  })
})

const isShow = ref(false)

const toggleImportModal = () => {
  isShow.value = !isShow.value
}

const MSKWGSDropdownActions = ref([
  {
    id: 1,
    label: 'Импорт из файла',
    divided: true
  },
  {
    id: 2,
    label: 'Экспорт в xlsx'
  },
  {
    id: 'kml',
    label: 'Экспорт в kml'
  },
  // {
  //   id: 'kmz',
  //   label: 'Экспорт в kmz'
  // },
  {
    id: 'gpx',
    label: 'Экспорт в gpx'
  }
])

const WGSMSKDropdownActions = ref([
  {
    id: 1,
    label: 'Импорт из файла',
    divided: true
  },
  {
    id: 2,
    label: 'Экспорт в xlsx'
  },
  {
    id: 'dxf-layers',
    label: 'Экспорт в dxf (Слои)'
  },
  {
    id: 'dxf-blocks',
    label: 'Экспорт в dxf (Блоки)'
  }
])

const dropdownHandler = (id) => {
  switch (id) {
    case 1:
      toggleImportModal()
      break
    case 2:
      excelExport()
      break
    case 'kml':
      {
        exportFileRequest('kml', 'wgs')
      }
      break
    case 'gpx':
      {
        exportFileRequest('gpx', 'wgs')
      }
      break
    // case 'kmz':
    //   {
    //     exportFileRequest('kmz', 'wgs')
    //   }
    //   break
    case 'dxf-layers':
      {
        exportFileRequest('dxf-layers', 'msk', 'dxf')
      }
      break
    case 'dxf-blocks':
      {
        exportFileRequest('dxf-blocks', 'msk', 'dxf')
      }
      break
  }
}

const exportFileRequest = async (url, converter, isDXF) => {
  const filteredData = cloneDeep(store.pointsList).filter(
    (point) => point.id && point.lat && point.lon
  )

  filteredData.forEach((item) => {
    const zone = getZone(item.zone_in)
    if (!zone) return
    item.zone_in = zone.id
  })

  const { data } = await axios.put(
    `api/v1/converter/${converter}/to/${url}/export`,
    filteredData
  )

  downloadFile(data, url, isDXF)
}

const mapExtension = {
  gpx: 'gpx',
  // kmz: 'kmz',
  kml: 'kml',
  'dxf-blocks': 'dxf',
  'dxf-layers': 'dxf'
}

const mapExtensionBlobType = {
  gpx: 'gpx',
  kml: 'kml',
  // kmz: 'vnd.google-earth.kmz',
  'dxf-blocks': 'dxf',
  'dxf-layers': 'dxf'
}

const downloadFile = (data, extension, isDXF) => {
  const blob = new Blob([data], {
    type: `application/${mapExtensionBlobType[extension]}`
  })

  let url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'

  if (isDXF) {
    a.href = `data:application/octet-stream;base64,${btoa(
      unescape(encodeURIComponent(data))
    )}`
  } else {
    a.href = url
  }

  a.download = `${toDay()}.${mapExtension[extension]}`
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}

const filteredDropdownActions = computed(() => {
  if (!defaultSelectedValue.value.id) {
    return WGSMSKDropdownActions.value
  }

  if (defaultSelectedValue.value.id === 'WGS-MSK') {
    return WGSMSKDropdownActions.value
  }

  return MSKWGSDropdownActions.value
})
</script>
<template>
  <div class="sheets-toolbar">
    <div class="sheets-toolbar__wrapper">
      <div class="sheets-toolbar__sheets-actions">
        <div
          v-for="action in actionsItems"
          :key="action.id"
          class="sheets-toolbar__action"
          @click="sheetsActionsHandler(action.id)"
          v-tooltip.right="action.tooltipText"
        >
          <s-icon
            :name="action.iconName"
            size="xl"
          />
        </div>
      </div>
      <s-button
        class="sheets-toolbar__clear-btn"
        @click="clearTable"
      >
        {{ screenWidth > 1300 ? 'Очистить таблицу' : 'Очистить' }}
      </s-button>
      <s-select
        :value="defaultSelectedValue?.id"
        @change="selectHandle"
        :options="coordinateConversions"
      />
    </div>
    <div class="sheets-toolbar__exports">
      <s-dropdown
        min-width="178"
        list
      >
        <s-button icon="file-export">Импорт / Экспорт</s-button>
        <template #dropdown>
          <s-dropdown-item
            v-for="item in filteredDropdownActions"
            :id="item?.id"
            :key="item.id"
            :type="item.type"
            :icon="item.icon"
            :label="item.label"
            :divided="item.divided"
            :close-by-click="item?.closeByClick"
            @click="dropdownHandler"
            :centerItem="false"
          />
        </template>
      </s-dropdown>
    </div>
  </div>
  <table-import-modal
    @close="toggleImportModal"
    @toggle="toggleImportModal"
    :show-modal="isShow"
  />
</template>
<style lang="scss">
.sheets-toolbar {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  height: 40px;

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__clear-btn {
    flex-shrink: 0;
    border-radius: 8px !important;
  }

  & .s-select {
    max-width: 140px;
  }

  &__wrapper {
    display: flex;
    gap: 24px;
  }

  &__exports {
    display: flex;
    gap: 24px;
  }

  & .s-dropdown-item {
    justify-content: start;
  }

  &__sheets-actions {
    display: flex;
    align-items: center;
    border: 1px solid var(--main-bg);
    border-radius: 8px;
  }

  &__action {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg);
    cursor: pointer;

    & svg {
      width: 16px;
      height: 16px;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
}
</style>
