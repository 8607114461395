<script setup>
import { SModal, SButton, SText } from '@n966/soilbox_ui'
import { updatesConfig } from './updates/config'

import UpdateComp from './updates/update-comp.vue'
import PythonCourse from './updates/python-course.vue'

defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['close-modal'])

const componentMap = {
  'python-course': PythonCourse
}
</script>

<template>
  <s-modal
    title="Последние обновления"
    :show="showModal"
    @close="emits('close-modal')"
  >
    <div class="updates-modal">
      <section
        class="updates-modal__item"
        v-for="config in updatesConfig"
        :key="config"
      >
        <s-text
          v-if="config.date"
          type="secondary"
        >
          <i>{{ config.date }}</i>
        </s-text>

        <component
          v-if="config.component"
          :is="componentMap[config.component]"
        />
        <update-comp
          v-else
          :config="config"
        />
      </section>
    </div>

    <template #footer>
      <s-button
        stretch
        @click="emits('close-modal')"
      >
        Понятно
      </s-button>
    </template>
  </s-modal>
</template>

<style lang="scss">
.updates-modal {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  padding-right: 0.5rem;

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &:not(:last-child) {
      &:after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: var(--bg);
        position: absolute;
        bottom: -4rem;
      }
    }
  }
}
</style>
