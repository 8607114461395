export default {
  install: (app, options) => {
    // Если нужно ограничить запуск только в production
    // if (import.meta.env.NODE_ENV !== 'production') {
    //   return;
    // }

    ;(function (w, d, u) {
      const s = d.createElement('script')
      s.async = true
      s.src = u + '?' + ((Date.now() / 60000) | 0)
      const h = d.getElementsByTagName('script')[0]
      h.parentNode.insertBefore(s, h)
    })(
      window,
      document,
      'https://cdn-ru.bitrix24.ru/b32562484/crm/site_button/loader_2_88uhbz.js'
    )
  }
}
